
// @ts-nocheck


export const localeCodes =  [
  "de_marketing_environment",
  "de",
  "en",
  "fr"
]

export const localeLoaders = {
  "de_marketing_environment": [{ key: "../locales/de_marketing_environment.json", load: () => import("../locales/de_marketing_environment.json" /* webpackChunkName: "locale__app_locales_de_marketing_environment_json" */), cache: true }],
  "de": [{ key: "../locales/de.json", load: () => import("../locales/de.json" /* webpackChunkName: "locale__app_locales_de_json" */), cache: true },
{ key: "../locales/customer-module/de.json", load: () => import("../locales/customer-module/de.json" /* webpackChunkName: "locale__app_locales_customer_module_de_json" */), cache: true }],
  "en": [{ key: "../locales/en.json", load: () => import("../locales/en.json" /* webpackChunkName: "locale__app_locales_en_json" */), cache: true },
{ key: "../locales/customer-module/en.json", load: () => import("../locales/customer-module/en.json" /* webpackChunkName: "locale__app_locales_customer_module_en_json" */), cache: true }],
  "fr": [{ key: "../locales/fr_pdf_translations.json", load: () => import("../locales/fr_pdf_translations.json" /* webpackChunkName: "locale__app_locales_fr_pdf_translations_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "./server/utils/nuxtI18nLocaleDetector.ts",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": true
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de_marketing_environment",
      "domain": "app-demo.hos.k8s.sidestream.tech",
      "files": [
        "locales/de_marketing_environment.json"
      ]
    },
    {
      "code": "de",
      "name": "Deutsch",
      "domain": "(.*?)",
      "files": [
        "locales/de.json",
        "locales/customer-module/de.json"
      ]
    },
    {
      "code": "en",
      "name": "English",
      "domain": "(.*?)",
      "files": [
        "locales/en.json",
        "locales/customer-module/en.json"
      ]
    },
    {
      "code": "fr",
      "name": "Français",
      "domain": "(.*?)",
      "files": [
        "locales/fr_pdf_translations.json"
      ]
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "./locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de_marketing_environment",
    "domain": "app-demo.hos.k8s.sidestream.tech",
    "files": [
      {
        "path": "locales/de_marketing_environment.json"
      }
    ]
  },
  {
    "code": "de",
    "name": "Deutsch",
    "domain": "(.*?)",
    "files": [
      {
        "path": "locales/de.json"
      },
      {
        "path": "locales/customer-module/de.json"
      }
    ]
  },
  {
    "code": "en",
    "name": "English",
    "domain": "(.*?)",
    "files": [
      {
        "path": "locales/en.json"
      },
      {
        "path": "locales/customer-module/en.json"
      }
    ]
  },
  {
    "code": "fr",
    "name": "Français",
    "domain": "(.*?)",
    "files": [
      {
        "path": "locales/fr_pdf_translations.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
